import logo from "./logo.webp"
const clientData = {
    client_entity: 27,
    attorney_fimr: 'Gorski Law Office',
    attorney_name: 'Jeff Gorski',
    attorney_number: '(502) 586-3680',
    attorney_email: 'jgorskilaw@aol.com',
    client_video_source: ' https://erc-videos.s3.amazonaws.com/ertc_explainer_-_gorski_law_office+(720p).mp4',
    mainColor: '#3C3C3C',
    secondaryColor: '#DB3539',
    siteLink: 'http://www.bluegrasslawyers.com',
    logo
}

export default clientData